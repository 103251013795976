.map{
  width: 100%;
  height: 100vh;
}


.responsive {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  font-size: 2vw;
}

.container {
  display: grid;
  grid-template-columns: 2fr 8fr auto;
  grid-template-rows: auto;
  background-color: #f2f5fa;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  overflow: hidden;
}

.hidden .persons {
  width: 0;
}

.hidden .map {
  width: 100%;
}

.nav form {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 15px;
  -webkit-box-shadow: -1px 2px 2px #ececeb;
  box-shadow: -1px 2px 2px #ececeb;
}

.nav form button {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.nav form input {
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 25px;
  width: 100%;
}
 
.nav {
  box-shadow: 1px 2px 2px #ececeb;
  color: #adadad;
  background-color: #1d1d1d;
  position: sticky;
  top: 0;
  z-index: 1000;
  height:4em;
}

.nav > ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
  padding-inline-end: 1em;
  padding-inline-start: 1em;
  margin: 0;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  height: 100%;
}

.nav ul span.hry {
  height: 60px;
  width: 1px;
  background-color: #cccccc;
  display: inline-block;
}

.nav ul li.text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav ul li {
  padding: 15px 0px;
  margin: 0px 10px;
  min-width: 0;
}

.nav ul li.ml_auto {
  margin-inline-start: auto;
}

.nav ul li.active_item_menu_top {
  /*border-bottom: 1px solid #5f27cd;*/
  border-bottom: 2px solid whitesmoke ;
  padding-bottom: 0;
  padding-top: 0;
}

.nav ul li.active_item_menu_top a {
  /*color: #1e272e;*/
  color: whitesmoke;
}
.nav ul li.profile img{
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  object-fit: cover;
}
.nav ul li.profile {
  border-radius: 50%;
  overflow: hidden;
  width: 1.7em;
  padding: 0;
  margin: 0;
  align-items: center;
  justify-content: center;
  display: flex;
  position: relative;
  min-height: 1.7em;
  background: var(--background);
}

.nav ul li.profile_dropdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 18px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.nav ul li.profile_dropdown:hover ul {
  visibility: visible;
  opacity: 1;
}

.nav ul li.profile_dropdown span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 0.6em;
  color: #a6afb3;
}

.nav ul li.profile_dropdown span span:first-child {
  font-weight: bolder;
  color: white;
  font-size: 0.9em;
}
.nav ul li.profile_dropdown span span:last-child {
  font-size: 0.7em;
}

.nav ul li.profile_dropdown i {
  cursor: pointer;
}

.nav ul li.profile_dropdown ul {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  z-index: 1000;
  background-color: rgb(29, 29, 29);
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  visibility: hidden;
  opacity: 0;
  padding: 5px;
  overflow: hidden;
  border-radius: 2px;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  /*border-left: 2px solid #5f27cd;*/
  border-left: 2px solid whitesmoke;
}

.nav ul li.profile_dropdown ul:hover {
  visibility: visible;
  opacity: 1;
}

.nav ul li.profile_dropdown ul li {
  width: 100%;
  padding: 5px;
  margin: 0;
  font-size: 0.9em;
  border-radius: 2px;
}

.nav ul li.profile_dropdown ul li:hover {
  /*background-color: #ecf0f1;*/
  background-color: #434343;
}

.nav ul li.profile_dropdown ul li a {
  display: block;
}

.nav ul li a {
  color: inherit;
  text-decoration: none;
  font-size: 0.7em;
  position: relative;
}

.nav ul li a > span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px #ececeb;
  width: 1.87em;
  height: 1.87em;
}

.nav ul li a > span img {
  width: 1.05;
}

.nav ul li a i {
  font-size: 1.1em;
  position: relative;
  display: block;
}

.nav ul li a :global(i.fa-envelope) span {
  background-color: #0abde3;
}

.nav ul li a :global(i.fa-bell) span {
  background-color: #ff6b6b;
}

.nav ul li a i span {
  position: absolute;
  left: -50%;
  bottom: -50%;
  font-size: 0.6em;
  color: rgb(255, 255, 255);
  padding: 2px;
  border-radius: 50%;
  color: #ffffff;
  font-style: normal;
  width: 1.7em;
  height: 1.7em;
  text-align: center;
}

div.persons {
  background-color: white;
  height: 100%;
  min-width: 5em;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  transition: width 0.2s linear;
  font-size: 1.2em;
  padding-top: 10px;
}

div.persons form {
  padding: 0px 4px 8px;
  border-bottom: 2px  solid #ececeb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 5px;
  gap: 5px;
  height: 2em;
  min-height: 2em;
  position: relative;
}

div.persons form button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 1em;
  position: absolute;
  left: 100%;
  transform: translate(-200%, 0);
}

div.persons form > span {
  border: 1px solid #ececeb;
  position: relative;
  padding: 8px;
  border-radius: 15.5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

div.persons form > span button[type="submit"] > embed>#document> svg {
  color: #576574;
}
div.persons form > span button[type="submit"] {
  position: absolute;
  top: 50%;
  left: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #576574;
  font-size: 0.8em;
}

div.persons form > span input {
  outline: none;
  border: none;
  background-color: transparent;
  padding-left: 25px;
  padding-right: 5px;
  font-size: 0.5em;
  width: 100%;
}

div.persons ul {
  padding: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

div.persons ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  padding: 6px;
  border-radius: 0.3px;
  cursor: pointer;
}

div.persons ul li.display-none {
  display: none;
}

div.persons ul li:hover {
  background-color: #ecf0f1;
}

div.persons ul li > span:nth-child(1) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 10px;
  width: 100%;
}

div.persons ul li > span:nth-child(1) input {
  cursor: pointer;
}

div.persons ul li > span:nth-child(1) svg,div.persons ul li > span:nth-child(1) img {
  width: 1.56em;
  height: 1.56em;
  min-width: 1.56em;
  min-height: 1.56em;
  border-radius: 50%;
}

div.persons ul li > span:nth-child(1) a {
  color: #2b1c6e;
  font-weight: bolder;
  font-size: 0.8em;
  text-decoration: none;
}

div.persons ul li > span {
  font-size: 0.6em;
  color: #576574;

}
div.map {
  /* background-image: url("../images/map.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: -webkit-fill-available;
  transition: inherit;
}
.item_person{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.activeperson{
  background-color: #dbdbdb;
}

.viewAll{
  font-size: 0.55em;
  margin-top: 10px;
  cursor: pointer;
  color: #0000ee;
  /*box-shadow: 0 0 6px 0px #cecece;*/
  width: fit-content;
  margin: 10px  auto 0;
  padding: 4px  6px;
  /*border: 1px  solid #cecece;*/
  max-width: 100%;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  display: flex;
  align-items: center;
  text-decoration: underline;
  text-overflow: ellipsis;
}

.name{
  font-size: 1em;
  font-weight: bold;
  color: white;
}
@media (max-width:992px) {
  .name{
    margin-inline-start: 2em !important;
  }
}

:global .MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
  width: 100%;
}

.holder{
  height: 2.9em;
}

@media screen and (max-width: 700px) {
  .responsive {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .container {
    display: none !important;
  }
}

@media (max-width:992px){
  .mobile{
    /*display: none !important;*/
  }
}
