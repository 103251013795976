.activePanel{
    border: .1em solid #fca5a5;
    border-radius: .3em
}
.star{
    color: red;
}
.textArea{
    width: 100%;
    height: 15em;
    resize: none;
    padding: 1em;
    border: .1em solid #8f8f8f;
    border-radius: .4em
}
.textArea:focus{
    outline: none;
}
.mapContainer {
    width: 100%;
    height: 20em;
}
.phoneContainer{
    /*display: flex;*/
    /*align-items: center;*/
    /*direction:ltr;*/

}
.phoneContainer input{
    width: 100% !important;
}
