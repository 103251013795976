.wrapper{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
}

.btnwrapper{
  position: relative;
  font-size: 0.8em;
}
.btnsth{
  background-color: white;
  box-shadow:rgba(0 ,0 ,0 ,0.3) 0 1px 4px -1px;
  display: flex;
  white-space: nowrap;
  height: 2.5em;
  justify-content: center;
  border-radius: 20%/100%;
  align-items: center;
  padding: 1em;
  width: fit-content;
}
.wrapper :global(.swiper-container){
  position: absolute;
  height: 3.1em;
  width: calc(100% - 5em);
  right: 2.5em;
  left: 2.5em;
}
.wrapper :global(.swiper-wrapper){
  position: absolute;
  top: 6px;
  height: 2.5em;
}
.wrapper :global(.swiper-slide){
  width: fit-content;
}
.btnnearme label{
  -webkit-tap-highlight-color:transparent;
}

.btnnearme{
  color: #282828;
  width: fit-content;
  margin-right: 2.1em;
}
.icon>svg{
  display: block;
}
.icon{
  font-size: 1.5em;
  right: 0;
  top: -0em;
  position: absolute;
  height: 1em;
}
.swipernavigation>div>img{
  width: 1em;
}
.swipernavigation>div::after{
  height: 1em;
  width: 0;
  display: none;
}
.swipernavigation>div{
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0.125em solid #282828;
  height: 2.2em;
  width: 2.2em;
  position: unset;
  margin-top: 0;
  background-color: #282828;
}
.swipernavigation{
  margin-top: 0.55em;
  display:flex;
  justify-content:space-between;
  position: absolute;
  width: 100%;
  height: 2.3em;
}
.mapContainerClassName *{
  overflow: visible;
  /* height: 50vh; */
}

.snavcontainer{

  height: 3.5em;
  position: absolute;
  overflow: hidden;
  width: 100%;
  border-inline: 2.6em solid transparent;
}
/* only for safari browsers */
/*noinspection CssInvalidPseudoSelector*/
_::-webkit-full-page-media, .snavcontainer {
  padding:0 35px; 
}

.Location{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-100%);
  font-size: 3.5em;
}

@media (min-width: 800px) {
  .Location{
    font-size: 50px;
  }
}
