.fill,
.fill path,
.fill rect {
    fill: currentColor;
}

.stroke,
.stroke path,
.stroke rect {
    stroke: currentColor;
}

.sidebar a img {
    width: 9em;
    margin: auto;
}

.sidebar img {
    width: 2em;
    min-width: 2em;
}

a.sidebar_btn,
a.sidebar_btn:visited {
    font-size: 0.7em;
    color: #fabc27;
    white-space: nowrap;
    display: flex;
    max-width: max(0px, calc((100% - 100px) * 9));
    overflow: hidden;
    align-items: center;
    gap: 0.5em;
}

.sidebar {
    position: relative;
    min-width: unset;
    width: 100% !important;
}

/* :global .pro-sidebar.sm{
  position: relative !important;
} */
/*button.handle:hover>span.arrowback{*/
/*  background-color: hsl(0, 0%, 20%);*/
/*  !*border-left: none;*!*/
/*}*/
/*button.handle:hover>span.arrowback:hover{*/
/*  color: black;*/
/*  background-color: hsl(0, 0%, 80%);*/
/*}*/
.arrowback {
    cursor: pointer;
    position: absolute !important;
    transform: translate(50%, -20%);
    font-size: 1em;
    top: 3.6em;
    z-index: 2212;
    right: 0;
    left: 0;
    margin-left: auto;
    transition: transform 0.3s, color 0.3s, background-color 0.3s, opacity 0.3s,
    visibility 0.3s;
    padding: 0.2em;
    height: 1.4em;
    width: 1.4em;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: hsl(0, 0%, 20%);
    color: #fabc27;
    border: 1px solid #fabc27;
    opacity: 0;
    visibility: hidden;
}

:global(.rtl) .arrowback {
    transform: translate(50%, -20%) rotate(180deg);
    right: 100%;
    left: 100%;
}

.collapsed .arrowback {
    transform: translate(50%, -20%) rotate(180deg);
}

:global(.rtl) .collapsed .arrowback {
    transform: translate(50%, -20%);
}

.resizable:hover .arrowback {
    opacity: 1;
    visibility: visible;
}

.resizable {
    height: 100% !important;
}

.header > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.header {
    display: flex;
    padding: 0.2em 1em;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    letter-spacing: 1px;
    overflow: hidden;
    gap: 0.7em;
    height: 3.6em;
}

.header span {
    font-size: 0.8em;
}

.image {
    width: 9em;
    height: 3em;
    border-radius: 50%;
}

.header.collapsedHeader {
    padding: 0.425em;
    justify-content: center;
}

/*.collapsedHeader .image{*/
/*  !*width: 2em;*!*/
/*  !*height: 2em;*!*/
/*  !*flex-shrink: 0;*!*/
/*}*/

.collapsedHeader span {
    display: none;
}

.resizable > div ::-webkit-scrollbar {
    background-color: #1d1d1d;
}

.resizable > div ::-webkit-scrollbar-thumb {
    background-image: linear-gradient(0.25turn, #1d1d1d 0%, #cccccc 100%);
}

.handel > div {
    cursor: ew-resize !important;
    z-index: 1201;
    width: 0 !important;
}

button.handle {
    cursor: ew-resize;
    height: 100%;
    width: 0;
    padding: 0;
    border: 0;
    background-color: transparent;
}

button.handle > span {
    position: relative;
    right: -0.9em;
    left: -0.9em;
    display: block;
    height: 100%;
    width: 0.5em;
    transition: background-color 0.2s;
}

button.handle:focus > span {
    border-inline-end: 3px solid #fabc27;
}

button.handle:hover > span {
    border-inline-end: 3px solid #fabc27;
}

.sidebarwrapper {
    position: relative;
    min-width: inherit;
    max-width: inherit;
    width: inherit;
    height: 100%;
}

.poweredBy {
    font-size: 0.7em;
}

/* :global .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu:hover{
 z-index: 1202;
} */

@media (min-width: 500px) {
    .Chat {
        display: none;
    }
}

@media (max-width: 992px) {
    .resizable {
        position: fixed !important;
        z-index: 1001;
        left: -300px;
    }

    .resizable.rtl {
        right: -300px;
        left: auto;
    }

    .resizable.active {
        position: fixed !important;
        z-index: 1001;
        left: 0;
    }

    .resizable.rtl.active {
        right: 0;
        left: auto;
    }

    .arrowback {
        display: none;
    }

    .sidebar {
        width: unset !important;
    }
}
