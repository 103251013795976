.container {
  display: flex;
  height: 100%;
  width: 100%;
}
.main {
  /* width: 100%; */
  flex-grow: 1;
  overflow: auto;
  transition: width 0.3s;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.btn_toggle {
  /* position: fixed;
  top:1.1em;
  left: .5em;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 1.8em;
  min-height: 1.8em;
  padding: 0.4em 0.2em;
  cursor: pointer;
  transform: rotateY(180deg);
  display: none;
  z-index: 1000; */

  position: fixed;
  top: 1.1em;
  left: 0.5em;
  background: #000000;
  box-shadow: 0px 2px 5px 3px rgb(90 90 90);
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  min-width: 1.8em;
  min-height: 1.8em;
  border: 1px solid #ffc107;
  padding: 0.4em 0.2em;
  cursor: pointer;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  display: none;
  z-index: 1000;
}
.btn_toggle.rtl{
  left:auto;
  right:.5em;
  transform: rotateY(0deg);
}
.unAuth{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}
.availabilityBtn{
  position: relative;
  overflow: hidden;
}
.errorIconContainer{
  position: absolute;
  background-color: #7f1d1d;
  right: -2.1em;
  width: 7em;
  height: 100%;
  top: -2em;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.errorIconContainer.rtl{
  position: absolute;
  background-color: #7f1d1d;
  left: -2.1em;
  right:auto;
  width: 7em;
  height: 100%;
  top: -2em;
  -webkit-transform: rotate(132deg);
  transform: rotate(132deg);
}
.errorIconContainer.rtl button{
  top: 0;
  bottom: auto !important;
  -webkit-transform: rotate(230deg);
  transform: rotate(230deg);
}
.errorIconContainer button {
  position: absolute;
  color: #a1a1aa;
  bottom: 0;
  right: 23%;
  display: flex;
  margin: 0;
  padding: 0;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

@media (max-width: 992px) {
  .btn_toggle {
    display: flex;
  }
}

a.navLink{
  display: block;
  max-height: 51px;
}

a.navLink:global(active){
  color: #d8d8d8 !important;
}
a.navLink:before {
  content: none !important;
}

