.main {
  padding: 1em;
  width: 95%;
  margin: 10px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 200ms;
}

/*.main:hover {*/
/*  transform: scale(1.05);*/
/*}*/

.alertIcon{
  stroke: tomato;
  width: 45px;
  height: 45px;
  margin-inline-end: 25px;
}

.refreshBtn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.restartIcon {
  width: 15px;
  height: 15px;
  fill: tomato;
}

.errorText {
  font-size: 15px;
}

.tryAgain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tryAgainText {
  font-size: 12px;
  text-decoration: underline;
}

.refreshBtn {
  background-color: transparent;
  border: none;
  outline: none;
  transition: transform 200ms;
}

.refreshBtn:hover {
  transform: scale(1.2);
}
